import { css } from '@emotion/core';
import {
    primaryColour,
    breakpoint,
    ScreenWidth,
    interestedColour,
    navigationColour,
    inactiveGreyColour,
    secondaryColour,
    primaryFont,
} from '../global.style';

export const container = css({
    margin: '0px',
    marginTop: '5px',
    width: '85%',
    padding: '10px',
    [breakpoint(ScreenWidth.SM)]: {
        marginTop: '20px',
        width: '100%',
        padding: '0px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: '105px',
    },
});

export const introColumn = css({
    display: 'flex',
    flexDirection: 'column',
    color: primaryColour,
    textAlign: 'left',
    h1: {
        fontSize: '40px',
        marginBottom: '20px',
        width: '100%',
    },
    p: {
        fontSize: '19px',
        margin: '0px',
        width: '100%',
        marginBottom: '20px',
    },
    [breakpoint(ScreenWidth.SM)]: {
        marginLeft: '10px',
        marginRight: '20px',
        h1: {
            whiteSpace: 'nowrap',
            margin: '0px',
            fontSize: '50px',
            marginBottom: '10px',
            lineHeight: '1.1',
        },
        p: {
            letterSpacing: '0.03rem',
            margin: '0px',
            color: navigationColour,
            width: '380px',
            height: '84px',
            fontSize: '18px',
            marginBottom: '20px',
        },
    },
    [breakpoint(ScreenWidth.MD)]: {
        marginLeft: '20px',
        marginRight: '20px',
        h1: {
            whiteSpace: 'nowrap',
            margin: '0px',
            fontSize: '70px',
            marginBottom: '10px',
        },
        p: {
            letterSpacing: '0.03rem',
            margin: '0px',
            color: navigationColour,
            width: '496px',
            height: '84px',
            fontSize: '22px',
            marginBottom: '20px',
        },
    },
    [breakpoint(ScreenWidth.LG)]: {
        marginLeft: '85px',
        marginRight: '80px',
        h1: {
            whiteSpace: 'nowrap',
            margin: '0px',
            fontSize: '70px',
            marginBottom: '10px',
        },
        p: {
            letterSpacing: '0.03rem',
            margin: '0px',
            color: navigationColour,
            width: '496px',
            height: '84px',
            fontSize: '22px',
            marginBottom: '20px',
        },
    },
});

export const button = css({
    display: 'block',
    width: '233px',
});

export const imageColumn = css({
    display: 'none',
    margin: '0px',
    [breakpoint(ScreenWidth.XS)]: {
        display: 'none',
        margin: '0px',
    },

    [breakpoint(ScreenWidth.SM)]: {
        display: 'block',
        marginRight: '20px',
        width: '330px',
        minWidth: '330px',
    },

    [breakpoint(ScreenWidth.MD)]: {
        display: 'block',
        marginRight: '100px',
        width: '480px',
        minWidth: '410px',
    },
});

export const resumeLastSessionLink = css({
    color: interestedColour,
    marginTop: '5px',
    marginLeft: '30px',
    fontSize: '17px',
    fontWeight: 'bold',
    ':active, :link': {
        textDecoration: 'none',
    },
});

export const roundLink = css({
    cursor: 'hover',
    textAlign: 'center',
    textDecoration: 'none',
    borderRadius: '34px',
    border: `1px solid ${inactiveGreyColour}`,
    backgroundColor: secondaryColour,
    color: 'white',
    padding: '15px 0px',
    fontFamily: primaryFont,
    letterSpacing: '0.51px',
    fontWeight: 'bold',
    fontSize: '30px',
    width: '233px',
    outline: 'none',
    transitionDuration: '0.4s',
    ':hover': {
        backgroundColor: 'white',
        color: secondaryColour,
        '*': {
            color: secondaryColour,
        },
    },
});
