import React, { useEffect, useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { introColumn, imageColumn, resumeLastSessionLink, container, roundLink } from './index.style';
import SEO from '../components/SEO/index';
import Img from 'gatsby-image';

export default function IndexPage() {
    const { t } = useTranslation('', { useSuspense: false });
    const [resumeLastSession, setResumeLastSession] = useState('');
    const data = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "landing-page-icon.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    useEffect(() => {
        const formSessionJson = localStorage.getItem('formSession');

        if (formSessionJson) {
            const formSession = JSON.parse(formSessionJson);
            const { page } = formSession;
            setResumeLastSession(page);
        }
    }, []);
    return (
        <div css={container}>
            <SEO title={t('index.title')} description={'index.pageDescription'} />
            <div css={introColumn}>
                <h1>
                    {t('index.powerful')}
                    <br />
                    {t('index.learning')}
                    <br />
                    {t('index.collaboration')}
                </h1>

                <p>{t('index.description')}</p>
                <Link css={roundLink} to={'/sign-up/my-school'}>
                    {t('index.getStarted')}
                </Link>
                {resumeLastSession && (
                    <Link css={resumeLastSessionLink} to={resumeLastSession}>
                        {t('index.resumeLastSession')}
                    </Link>
                )}
            </div>
            <div css={imageColumn}>
                <Img fluid={data.file.childImageSharp.fluid} alt={t('index.images.landingPage')} />
            </div>
        </div>
    );
}
